<template>
  <div class="card">
    <div class="bg-blue-light p-1">
      <TitleButton
          :showBtn="false"
          title="Incentive Report"
      />
    </div>
    <div class="my-2 px-2">
      <DateQuerySetter
          btnTitle="Go"
          @onClickGo="onClickToGo"
          class="tds-vds-query-setter"
      >
        <div class="col-12 col-sm-6 col-md-3 col-lg-2">
          <label
              for="colFormLabel"
              class="col-form-label"
          >
            Referrer
          </label>
          <AsyncSelect
              placeholder="Select Referrer"
              v-model="referrer"
              :api-service="fetchContactProfiles"
              :additional-query="{ type: 'agent' }"
              :format-label="formatPatientLabel"
              :reduce="referrer => referrer.id"
          />
        </div>
        <div class="col-12 col-sm-6 col-md-3 col-lg-2">
          <label for="colFormLabel" class="col-form-label">Status: </label>
          <v-select
              placeholder="Select Status"
              v-model="status"
              :options="statusList"
              label="label"
              :reduce="label => label.value"
          />
        </div>
      </DateQuerySetter>
    </div>

    <div class="row">
      <div class="col-12">
        <div class="d-flex justify-content-end px-2">
          <button
              @click="onClickToPrint"
              class="btn btn-outline-secondary btn-sm btn-download-invoice waves-effect"
          >
            Print
          </button>
        </div>
      </div>
    </div>

    <div class="row">
      <div class="col-12 px-2 mt-1 mb-4">
      <div class="card p-1" style="min-height: 150px">
        <div class="table-responsive">
          <table class="table table-bordered">
            <thead>
            <tr>
              <th class="text-center">Referrer</th>
              <th class="text-center">Invoice Date</th>
              <th class="text-center">Invoice No</th>
              <th class="text-center">Patient Name (ID)</th>
              <th class="text-center">Total Invoice</th>
              <th class="text-center">Total Discount</th>
              <th class="text-center">Net Bill</th>
              <th class="text-center">Total Incentive</th>
              <th class="text-center">Referrer-Discount</th>
              <th class="text-center">Net Incentive</th>
            </tr>
            </thead>
            <tbody>
            <tr v-for="(item, i) in tableItems" :key="i">
              <td class="text-center">
                <td>{{ item.contact_referrer.full_name }} ({{ item.contact_referrer.patient_id }})</td>
              </td>
              <td>{{ item.date }}</td>
              <td>{{ item.bill_number }}</td>
              <td>{{ item.contact_profile.full_name }} ({{ item.contact_profile.patient_id }})</td>
              <td class="text-right">{{ item.subtotal_amount }}</td>
              <td class="text-right">{{ item.total_discount }}</td>
              <td class="text-right">{{ item.total_amount }}</td>
              <td class="text-right">{{ item.incentive_amount }}</td>
              <td class="text-right">{{ item.referrer_discount_amount }}</td>
              <td class="text-right">{{ item.net_incentive }}</td>
            </tr>
            <tr v-if="tableItems.length > 0">
              <td colspan="4">Total</td>
              <td class="text-right">{{ totalData.total_invoice }}</td>
              <td class="text-right">{{ totalData.total_discount }}</td>
              <td class="text-right">{{ totalData.net_bill }}</td>
              <td class="text-right">{{ totalData.total_incentive }}</td>
              <td class="text-right">{{ totalData.total_referrer_discount }}</td>
              <td class="text-right">{{ totalData.net_incentive }}</td>
              <td class="text-right"></td>
            </tr>
            </tbody>
          </table>
          <p v-if="tableItems.length <= 0" class="text-center mt-5">No data</p>
        </div>
      </div>
    </div>
    </div>

    <Loader v-if="loading"/>

  </div>
</template>

<script setup>
import TitleButton from '@/components/atom/TitleButton';
import {inject, onMounted, reactive, ref} from 'vue';
import { useStore } from 'vuex';
import { useRoute, useRouter } from 'vue-router';
import Loader from '@/components/atom/LoaderComponent'
import { computed } from 'vue';
import handleHospital from "@/services/modules/hospital";
import handleContact from '@/services/modules/contact'
import {useAsyncDropdownHelper} from "@/services/utils/asyncDropdownHelper";
import AsyncSelect from "@/components/molecule/input-field/AsyncSelect.vue";
import useDate from "@/services/utils/day";
import incentivePrinter from '@/services/utils/pdf/incentivePaymentDraft';
import handleCompany from "@/services/modules/company";
import DateQuerySetter from '@/components/atom/DateQuerySetter.vue';
import handleInventory from "@/services/modules/inventory";

const store = useStore();
const route = useRoute();
const router = useRouter();
const showError = inject('showError');
const showSuccess = inject('showSuccess');
const loading = ref(false)
const saveButtonLoader = ref(false)
const referrer = ref(null)
const date = ref(null)
const tableItems = ref([]);
const totalData = ref({});
const incentivePrintData = ref({});
const statusList = ref([]);
const status = ref([]);
const params = ref({
  offset: 30,
});
let companyInfo = reactive({})

const token = computed(() => localStorage.getItem('token'));

const { fetchIncentivePaymentReport } = handleHospital()
const {fetchContactProfiles} = handleContact()
const { formatPatientLabel } = useAsyncDropdownHelper()
const { fetchHome } = handleInventory()
const dateValue = useDate();
const { exportToPDF } = incentivePrinter();
const { fetchCompanyInfo } = handleCompany();

const onClickToGo = () => {
  getIncentivePaymentReport();
}

const getIncentivePaymentReport = async () => {
  const query = getQuery();
  loading.value = true
  tableItems.value = []

  await fetchIncentivePaymentReport(query).then(res => {
    if (!res.status){
      showError(res.message)
      return
    }

    tableItems.value = Array.isArray(res.data.data)
        ? res.data.data
        : Object.values(res.data.data);

    totalData.value = res.data.total;
  }).catch(err => {
    console.log(err)
  }).finally(() => {
    loading.value = false
  })
}

const onClickToPrint = async () => {
   const query = getQuery();

  await fetchIncentivePaymentReport(query).then((res) => {
    if (!res.status) {
      showError(res.message)
      return;
    }
    incentivePrintData.value = res
  }).then(() => {
    exportToPDF(companyInfo,incentivePrintData.value, true)
  }).catch((err) => {
    console.log("Something went wrong");
  });
}

const setCompanyInfo = async () => {
  await fetchCompanyInfo(route.params.companyId).then((res) => {
    companyInfo = res.data;
  }).catch((error) => {
    console.log(error);
  });
}

const getQuery = () => {
  let query = `?company_id=${route.params.companyId}`;
  if (referrer.value) query += '&referrer_id=' + referrer.value;
  if (status.value) query += '&status=' + status.value;
  if (route.query.start) query += '&start_date=' + route.query.start;
  if (route.query.end) query += '&end_date=' + route.query.end;
  return query;
}

onMounted(() => {
   date.value = dateValue.currentDate();
   setCompanyInfo()

  fetchHome().then(res => {
    if (res.data) {
      for (const value of Object.keys(res.data.incentive_payment_report_type)) {
        statusList.value.push({
          value: value,
          label: res.data.incentive_payment_report_type[value]
        })
      }
    }
  })

})

</script>
